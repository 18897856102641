<template>
  <div class="container">
    <div class="row" style="padding:1%">
      <div class="col-6">
        <img src="./assets/location.svg" style="width:25px" alt=""> Rue Forgeur 24 - 4000 LIEGE
      </div>
      <div class="col-6"><img src="./assets/speak.svg" style="width:25px" alt=""> Français, English, Čeština
      </div>
    </div>
  </div>
  <router-view />
</template>

<style>
@import url('https://fonts.googleapis.com/css?family=Montserrat:wght@500');
#app {
  font-family: Montserrat, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
