<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="card">

          <div class="card-body">
            <img src="../assets/doc.jpg" style="border-radius: 50%;" alt="...">
            <hr>
            <h1 class="card-title">Dr Katerina Igono</h1>
            <h5 class="card-text">Médecin généraliste</h5>
            <p>Un accompagnement préventif, attentif et personnalisé</p>
            <a style="border-radius: 30px;"
              href="https://progenda.be/calendars/docteur-igono-katerina-medecin-generaliste-rue-forgeur-24-4000-liege"
              class="btn btn-outline-primary btn-rounded">Rendez-vous</a>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <main style="text-align: left;">
          <div style="padding-top: 100px;">

            <div style="padding-top: 50px;
          padding-bottom: 50px;
          padding-right: 75px;
          padding-left: 50px;
          background-color: rgba(255, 255, 255, 0.90); color: rgb(0, 0, 0);">
              <h2>
                Mes Services
              </h2>

              <p>Je propose la prise en charge des maladies aiguës et chroniques. J’offre également des consultations
                centrées sur la prévention et le bien-être. Je m’occupe des personnes de tous âges.</p>

              <p>Je peux accueillir les patients en anglais, ce qui rend les consultations accessibles aux expatriés et
                aux
                étudiants Erasmus.</p>

              <p>Je suis ouverte à la discussion et prête à personnaliser les soins pour chaque personne, car chacun est
                un
                être unique.</p>
            </div>
          </div>
        </main>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-12">
        <h5><img src="../assets/time.svg" style="width:50px" alt=""> Consultations <strong>sans RDV entre 12:00 et
            13:30</strong>. Pour les
          situations plus complexes et pour une discussion plus approfondie, il est préférable de prendre rendez-vous
        </h5>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-12">
        <div class="card-group">
          <div class="card">
            <img src="../assets/doctor-health-healthcare-hospital-medical-medicine.png" style="margin:auto;width:170px;"
              alt="...">
            <div class="card-body">
              <h3 class="card-title">Examens Géneraux</h3>
            </div>
          </div>
          <div class="card">
            <img src="../assets/blood-test.svg" style="margin:auto;width:130px;padding-top: 30px;padding-bottom: 8px;">
            <div class="card-body">
              <h3 class="card-title">Prise de sang</h3>
            </div>
          </div>
          <div class="card">
            <img src="../assets/heart-shine.svg" style="margin:auto;width:130px;padding-top: 30px;">
            <div class="card-body">
              <h3 class="card-title">Prévention</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div style="text-align: left;background-color: steelblue; padding:20px;" class="row">
      <div class="row" style="color:white">
        <div class="col-8">
          <h1><span style="color:rgb(10, 10, 66)">Prenez</span> rendez-vous <br> à tout moment</h1>
        </div>
        <div class="col-4" style="text-align: center !important; margin-top: 4%;">
          <a style="border-radius: 30px;"
            href="https://progenda.be/calendars/docteur-igono-katerina-medecin-generaliste-rue-forgeur-24-4000-liege"
            class="btn btn-light btn-rounded btn-xxl"><strong>Réservez maintenant</strong></a>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-12">
        <h4><img src="../assets/phone-call.svg" width="30px" alt=""> &mdash; 0456311638 (par SMS)</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',

}
</script>
<style>
main {
  background-image: url('@/assets/banner.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}</style>